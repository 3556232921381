import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from "@/views/Login";
import NoteDetail from "@/components/NoteDetail";
import TrashDetail from "@/components/TrashDetail";
import NotebookList from "@/components/NotebookList";
import Home from "@/views/Home";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
}


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/home',
        alias: '/',
        component: Home,
        children: [
            {
                path: 'note',
                component: NoteDetail
            }, {
                path: 'trash',
                component: TrashDetail
            },
            {
                path: 'notebooks',
                component: NotebookList
            }

        ]
    },
    {
        path: '/login',
        component: Login
    },


]

const router = new VueRouter({
    routes
})

export default router
