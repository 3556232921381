<template>
  <span>{{ firstName }}</span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    data() {
      return {}
    },
  computed:{
      ...mapGetters(['firstName'])
  }

}
</script>

<style scoped>
span {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
  background: darkgray;
  color: #fff;
  text-shadow: 1px 0 1px #795c19;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 15px;
}
</style>
