<template>
  <div id="note" class="detail">
    <note-sidebar></note-sidebar>
    <div class="note-detail">
      <div class="note-empty" v-show="!curBook.id">请<router-link to="/home/notebooks">创建笔记本</router-link>后</div>
      <div class="note-empty" v-show="!curNote.id">选择或创建笔记</div>
      <div class="note-detail-ct" v-show="curNote.id">
        <div class="note-bar">
          <span> 创建日期: {{ curNote.createTimeformat }}</span>
          <span> 更新日期: {{ curNote.updateTimeformat }}</span>
          <span> {{ statusText }}</span>
          <span class="iconfont iconfont icon-trash1" @click="onDeleteNote"></span>
          <span class="iconfont" :class="isShowPreview?'icon-edit':'icon-eye'"
                @click="isShowPreview = !isShowPreview"></span>
        </div>
        <div class="note-title">
          <input type="text" v-model:value="curNote.title" @input="onUpdateNote" @keydown="statusText='正在输入...'"
                 placeholder="输入标题">
        </div>
        <div class="editor">
          <textarea v-show="isShowPreview" v-model:value="curNote.content" @input="onUpdateNote"
                    @keydown="statusText='正在输入...'" @click="checkTitle" placeholder="输入内容, 支持 markdown 语法"></textarea>
          <div class="preview markdown-body" v-html="previewContent" v-show="!isShowPreview"></div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import NoteSidebar from '@/components/NoteSidebar'
import _ from 'lodash'
import MarkdownIt from 'markdown-it'
import {mapGetters, mapMutations, mapActions} from 'vuex'

let md = new MarkdownIt()


export default {
  components: {
    NoteSidebar

  },

  data() {
    return {
      statusText: '笔记未改动',
      isShowPreview: true,

    }
  },

  created() {
    this.checkLogin({path: '/login'})
  },

  computed: {
    ...mapGetters([
      'notes',
      'curNote',
      'curBook'
    ]),

    previewContent() {
      return md.render(this.curNote.content || '')
    }
  },

  methods: {
    ...mapMutations([
      'setCurNote',

    ]),

    ...mapActions([
      'updateNote',
      'deleteNote',
      'checkLogin'
    ]),
    checkTitle() {
      if (this.curNote.title.length===0) {
        this.curNote.title = '无标题'
      }
    }
    ,
    onUpdateNote: _.debounce(function () {
      if (!this.curNote.id) return
      this.updateNote({noteId: this.curNote.id, title: this.curNote.title, content: this.curNote.content})
          .then(data => {
            this.statusText = '已保存'
          }).catch(data => {
        this.statusText = '保存出错'
      })

    }, 3000),

    onDeleteNote() {
      let noteIndex=this.notes.indexOf(this.curNote)

      let nextNoteIndex=0
      if(noteIndex+1<this.notes.length){
        nextNoteIndex=noteIndex+1
      }
      else if(noteIndex+1===this.notes.length&&this.notes.length!==1){
        nextNoteIndex=noteIndex-1
      }

      let nextNoteId=this.notes[nextNoteIndex].id
      this.deleteNote({noteId: this.curNote.id})
          .then(data => {

            this.$router.replace({
              path: '/note',
              query: {
                noteId: nextNoteId,
                notebookId: this.curBook.id
              }})
          })
    }

  },

  beforeRouteUpdate(to, from, next) {
    this.setCurNote({curNoteId: to.query.noteId})
    next()
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/note-detail.scss";

#note {
  display: flex;
  align-items: stretch;
  background-color: #fff;
  flex: 1;
}
</style>
