<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import Home from "@/views/Home";
export default {
  name:'app',
  components:{
    Home
  }
}
</script>
<style lang="scss">
@import '//at.alicdn.com/t/c/font_3601091_kj6a2nnvbsb.css';
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html, body, #app {
  height: 100%;
}
body {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
}
a {
  text-decoration: none;
  color: #444;
}
ul, li{
  list-style: none;
}
.btn {
  color: #666;
  font-size: 12px;
  padding: 2px 4px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px #ccc;
  border: none;
  cursor: pointer;
  display: inline-block;
}

</style>
