import request from "@/helper/request";
import {formatTime} from "@/helper/formatTime";


const URL={
    GET: '/notebooks',
    ADD: '/notebooks',
    UPDATE: '/notebooks/:id',
    DELETE: '/notebooks/:id'
}

export default {
    getAll(){
        return new Promise((resolve,reject)=>{
            request(URL.GET)
                .then(res=>{
                    res.data=res.data.sort((notebook1,notebook2)=>{notebook1.createdAt-notebook2.createdAt})
                    res.data.forEach(book=>{
                        book.createTimeformat=formatTime(book.createdAt)
                        book.updateTimeformat=formatTime(book.updatedAt)
                    })
                    resolve(res)
                }).catch(err=>{
                    reject(err)
            })
        })
    },

    updateNotebook(notebookId,{title=''}={title:''}){
        return request(URL.UPDATE.replace(':id',notebookId),'PATCH',{title})
    },
    deleteNotebook(notebookId){
        return request(URL.DELETE.replace(':id',notebookId),'DELETE')
    },
    addNotebook({title=''}={title:''}){
        return new Promise((resolve,reject)=>{
            request(URL.ADD,'POST',{title})
                .then(res=>{

                    resolve(res)
                    }
                ).catch(err=>{
                    reject(err)
            })
        })
    }

}
