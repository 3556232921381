import Auth from "@/apis/auth";
import router from "@/router";

const state = {
    user: null
}

const mutations = {
    setUser(state, payload) {
        state.user = payload.user
    }
}

const actions = {
    login({commit}, {username, password}) {
        return Auth.login({username, password})
            .then(res => {
                commit('setUser', {user: res.data})
            }).catch(err=>{
                alert('error')
            })
    },

    register({commit}, {username, password}) {
        return Auth.register({username, password})
            .then(res => {
                commit('setUser', {user: res.data})
            })
    },

    logout({commit}, payload = {path: '/login'}) {
        return Auth.logout()
            .then(res => {
                console.log(res)
                commit('setUser', { user: null })
                router.push(payload)
            })
    },
    checkLogin({ commit, state }, payload = { path: '/' }) {
        if(state.user !== null) return Promise.resolve()
        return Auth.getInfo()
            .then(res => {
                if(!res.isLogin) {
                    console.log('jump')
                    router.push(payload)
                } else {
                    commit('setUser', { user: res.data })
                }
            })
    }
}

const getters = {
    firstName(state){
        return state.user===null?'':state.user.username.substring(0,1).toUpperCase()
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}
