<template>
  <div class="detail" id="notebook-list">
    <header>
      <a href="#" class="btn" @click.prevent="onCreateBook"><i class="iconfont icon-plus"></i> 新建笔记本</a>
    </header>
    <main>

      <div class="layout">
        <h3>笔记本列表({{notebooks.length}})</h3>
        <div class="book-list">
          <router-link v-for="notebook in notebooks" :key="notebook.id" :to="`/note?notebookId=${notebook.id}`" class="notebook">
            <div>
              <span class="iconfont icon-notebook"></span> {{notebook.title}}
              <span>{{notebook.noteCounts}}</span>
              <span class="action" @click.stop.prevent="onEdit(notebook)">编辑</span>
              <span class="action" @click.stop.prevent="onDelete(notebook)">删除</span>
              <span class="date">{{ notebook.createTimeformat }}</span>
            </div>
          </router-link>
        </div>
      </div>

    </main>

  </div>

</template>

<script>
import {  mapActions, mapGetters } from 'vuex'


export default {
  data () {
    return {
      msg: '笔记本列表'
    }
  },
  created() {
    this.checkLogin({path: '/login'})
    this.getNotebooks()
  },
  computed:{
    ...mapGetters(['notebooks'])
},
  methods:{
    ...mapActions(['getNotebooks',
      'addNotebook',
      'updateNotebook',
      'deleteNotebook',
      'checkLogin']),

    onCreateBook(){
      this.$prompt('输入新笔记本标题', '创建笔记本', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.{1,30}$/,
        inputErrorMessage: '标题不能为空，且不超过30个字符'
      }).then(({ value }) => {
        this.addNotebook({ title: value })
      })
    },
    onDelete(notebook){
      this.$confirm('确认要删除笔记本吗', '删除笔记本', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteNotebook({ notebookId: notebook.id })
      })
    },
    onEdit(notebook){
      this.$prompt('输入新笔记本标题', '修改笔记本', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.{1,30}$/,
        inputErrorMessage: '标题不能为空，且不超过30个字符'
      }).then(({value})=>{
            this.updateNotebook({notebookId:notebook.id,title:value})
      }

      ).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/notebook-list.scss';

</style>
