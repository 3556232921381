<template>
  <div id="trash" class="detail">
    <div class="note-sidebar">
      <h3 class="notebook-title">回收站</h3>
      <div class="menu">
        <div>更新时间</div>
        <div>标题</div>
      </div>
      <ul class="notes">
        <li v-for="note in trashNotes">
          <router-link :to="`/trash?noteId=${note.id}`">
            <span class="date">{{note.updateTimeformat}}</span>
            <span class="title">{{note.title}}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="note-detail">
      <div class="note-bar" v-if="true">
        <span> 创建日期: {{ curTrashNote.updateTimeformat }}</span>
        <span> | </span>
        <span> 更新日期: {{ curTrashNote.createTimeformat }}</span>
        <span> | </span>
        <span> 所属笔记本: {{belongTo}}</span>

        <a class="btn action" @click="onRevert">恢复</a>
        <a class="btn action" @click="onDelete">彻底删除</a>
        <a class="btn action" @click="onDeleteAll">清空回收站</a>
      </div>
      <div class="note-title">
        <span>{{curTrashNote.title}}</span>
      </div>
      <div class="editor">
        <div class="preview markdown-body" v-html="compiledMarkdown"></div>
      </div>
    </div>
  </div>
</template>

<script>

import MarkdownIt from 'markdown-it'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import {Message} from "element-ui";

let md = new MarkdownIt()

export default {
  data () {
    return {}
  },

  created() {
    this.checkLogin({ path: '/login' })
    this.getNotebooks()
    this.getTrashNotes()
      .then(() => {
        this.setCurTrashNote({ curTrashNoteId: this.$route.query.noteId })
        this.$router.replace({
          path: '/home/trash',
          query: { noteId: this.curTrashNote.id }
        })
      })
  },

  computed: {
    ...mapGetters([
      'trashNotes',
      'curTrashNote',
      'belongTo'
      ]),

    compiledMarkdown () {
      return md.render(this.curTrashNote.content||'')
    }
  },

  methods: {
    ...mapMutations([
      'setCurTrashNote'
      ]),

    ...mapActions([
      'checkLogin',
      'deleteTrashNote',
      'revertTrashNote',
      'getTrashNotes',
      'getNotebooks'
      ]),

    onDelete() {
      this.$confirm('删除后将无法恢复', '确定删除？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          return this.deleteTrashNote({ noteId: this.curTrashNote.id })
        }).then(() => {
          console.log('delete success')
          this.setCurTrashNote()
          this.$router.replace({
            path: '/trash',
            query: { noteId: this.curTrashNote.id }
          })
        })

    },
    onDeleteAll(){
      this.$confirm('清空回收站无法恢复', '确定清空？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.trashNotes.forEach(note=>{
          return this.deleteTrashNote({ noteId: note.id })
        })

      }).then(() => {
        Message.success('已清空所有笔记')
        console.log('delete success')
        this.setCurTrashNote()
        this.$router.replace({
          path: '/trash',
          query: { noteId: this.curTrashNote.id }
        })
      })

    },

    onRevert() {
      this.revertTrashNote({ noteId: this.curTrashNote.id })
        .then(() => {
          this.setCurTrashNote()
          this.$router.replace({
            path: '/trash',
            query: { noteId: this.curTrashNote.id }
          })
        })
    }

  },

  beforeRouteUpdate (to, from, next) {
    this.setCurTrashNote({ curTrashNoteId: to.query.noteId})
    next()
  }

}
</script>

<style lang="scss">
@import '../assets/css/note-sidebar.scss';
@import '../assets/css/note-detail.scss';

#trash {
  display: flex;
  align-items: stretch;
  background-color: #fff;
  flex: 1;

  .note-bar {
    .action {
      float: right;
      margin-left: 10px;
      padding: 2px 4px;
      font-size: 12px;

    }
  }
 }

</style>
