import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import notebooks from './notebooks'
import note from "@/store/note";
import trash from "@/store/trash";
Vue.use(Vuex)

export default new Vuex.Store(
    {
        modules: {
            user,
            notebooks,
            note,
            trash
        }
    }
)
