import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
    relativeTime: {
        future: "in%s",
        past: "%s",
        s: '刚刚',
        m: "一分钟之前",
        mm: "%d分钟之前",
        h: "一小时之前",
        hh: "%d小时之前",
        d: "一天之前",
        dd: "%d days",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years"
    }
})

export function formatTime(time){
    let createTime=dayjs(time)
    let now =dayjs()
    let interval=now.diff(createTime,'hours')
    let str=''
    switch (true){
        case interval<1:

            str=dayjs(createTime).fromNow()
            break

        case interval>=1:
            str=createTime.format('YYYY-MM-DD')
            break
    }

    return str

}
