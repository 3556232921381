import request from '@/helper/request'
import {formatTime} from "@/helper/formatTime";
import dayjs from "dayjs";


const URL = {
    GET: '/notes/from/:notebookId',
    ADD: '/notes/to/:notebookId',
    UPDATE: '/notes/:noteId',
    DELETE: '/notes/:noteId'
}

export default {
    getAll({notebookId}) {
        return new Promise((resolve, reject) => {
            request(URL.GET.replace(':notebookId', notebookId))
                .then(res => {
                    res.data = res.data.map(note => {
                        note.createTimeformat = formatTime(note.createdAt)
                        note.updateTimeformat = formatTime(note.updatedAt)
                        return note
                    }).sort((note1, note2) => {
                        // return note2.updatedAt - note1.updatedAt
                        return dayjs(note2.updatedAt).unix() - dayjs(note1.updatedAt).unix();
                    })

                    resolve(res)
                }).catch(err => {
                reject(err)
            })
        })
    },

    updateNote({noteId}, {title, content}) {
        return request(URL.UPDATE.replace(':noteId', noteId), 'PATCH', {title, content})
    },

    deleteNote({noteId}) {
        return request(URL.DELETE.replace(':noteId', noteId), 'DELETE')
    },

    addNote({notebookId}, {title = '', content = ''} = {title: '', content: ''}) {
        return new Promise((resolve, reject) => {
            request(URL.ADD.replace(':notebookId', notebookId), 'POST', {title, content})
                .then(res => {

                    resolve(res)
                }).catch(err => {
                reject(err)
            })
        })
    }

}
