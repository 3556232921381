import axios from 'axios'
import baseURLConfig from './config-base-url'
import { Message } from 'element-ui'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.baseURL = baseURLConfig.baseURL
axios.defaults.withCredentials= true

export default function request(url, type = 'GET', data = {}) {
    return new Promise((resolve, reject) => {
        let options = {
            method: type,
            url: url,
            validateStatus: function (status) {
                return (status >= 200 && status < 300) || status === 400
            }
        }
        if (type.toLowerCase() === 'get') {
            options.params = data
        } else {
            options.data = data
        }
        axios(options).then(res => {
            if (res.status === 200) {
                resolve(res.data)
            } else {
                Message.error(res.data.msg);
            }
        }).catch(err=>{
            reject({ msg: '请求失败' })
        })

    })
}
