<template>
  <div id="login">
    <div class="modal">

      <div class="title">涟涟笔记</div>

      <div class="container">
        <ul>
          <li :class="{selected:loginVisible}" @click="showLogin">登录</li>
          <li :class="{selected:registerVisible}" @click="showRegister">注册</li>
        </ul>
        <div class="register" v-show="registerVisible">
          <input type="text" placeholder="请输入用户名" v-model="register.username" @blur="nameCheck">
          <span v-show="register.usernameCheck.isError">{{ register.usernameCheck.notice }}</span>
          <input type="password" placeholder="设置密码,至少6位字符" v-model="register.password" >
          <span v-show="register.passwordCheck.isError">{{ register.passwordCheck.notice }}</span>
          <button @click="onRegist">创建账户</button>
        </div>
        <div class="login" v-show="loginVisible">
          <input type="text" placeholder="用户名" v-model="login.username">
          <input type="password" placeholder="密码" v-model="login.password"
          @keyup.enter="onLogin">
          <button @click="onLogin">登录</button>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import {mapActions} from 'vuex';

export default {
  data() {
    return {
      loginVisible: true,
      registerVisible: false,
      login: {
        username: 'administer',
        password: 'administer'
      },
      register: {
        username: '',
        password: '',
        usernameCheck: {
          isError: false,
          notice: ''
        },
        passwordCheck: {
          isError: false,
          notice: ''
        }

      }
    }
  },

  methods: {
    ...mapActions({
      loginUser: 'login',
      registerUser: 'register'
    }),
    showLogin() {
      this.loginVisible = true
      this.registerVisible = false
    },
    showRegister() {
      this.registerVisible = true
      this.loginVisible = false
    },
    onRegist() {
      if (!/^[\w\u4e00-\u9fa5]{3,15}$/.test(this.register.username)) {
        this.register.usernameCheck.isError = true
        this.register.usernameCheck.notice = '用户名3~15个字符，仅限于字母数字下划线中文'
        return
      }
      if (this.register.password === '') {
        this.register.passwordCheck.isError = true
        this.register.passwordCheck.notice = '请输入密码'
        return
      }
      if (!/^.{6,16}$/.test(this.register.password)) {
          this.register.passwordCheck.isError = true
          this.register.passwordCheck.notice = '密码长度为6~16个字符'
        return
      }
      this.registerUser({username:this.register.username,password:this.register.password})
          .then(()=>{
            this.$message.success('注册成功')
            this.loginVisible = true
            this.registerVisible = false
          })

    },
    nameCheck(){
      if (/^[\w\u4e00-\u9fa5]{3,15}$/.test(this.register.username)) {
        this.register.usernameCheck.isError = false
      }
    },
    onLogin() {
      this.loginUser({
        username:this.login.username,
        password:this.login.password
      }).then(()=>{
        this.$router.push({path:'home/note'})
      })
    }
  }


}

</script>

<style lang="scss" scoped>
@import "../style/helper";

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 162px;
  color: $color-gray;
  font-size: 24px;
}

.container {

  margin-top: 45px;
  width: 380px;
  min-height: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;

  > ul {
    height: 52px;
    display: flex;
    align-items: center;

    > li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;
      text-align: center;
      font-size: 20px;
      color: $color-gray;
      background-color: darken($color-lightGray, 5%);

      &.selected {
        background-color: white;
      }

    }
  }

  .register {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding-top: 34px;

    input {
      margin-top: 16px;
      border-radius: 6px;
      width: 300px;
      height: 38px;
      border: 1px solid darken($color-lightGray, 20%);
      padding: 0 15px;
    }

    span {
      width: 300px;
      margin-top: 8px;
      padding-left: 5px;
      font-size: 8px;
      text-align: left;
      color: #c0615c;
    }

    button {
      width: 300px;
      height: 42px;
      background-color: #5ebb6b;
      border: none;
      border-radius: 6px;
      color: white;
      margin-top: 20px;
      margin-bottom: 38px;
    }
  }

  .login {
    @extend .register
  }

}


</style>

