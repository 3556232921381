<template>
  <div class="home">
    <sidebar></sidebar>
    <router-view/>
  </div>
</template>

<script>


import sidebar from "@/components/Sidebar";

export default {
  name: 'Home',
  components: {
    sidebar
  }
}
</script>

<style scoped lang="scss">
.home{
  height: 100%;
  display: flex;
}

</style>
